<template>
  <div
    class="abrir_caixa row justify-content-center d-flex align-items-baseline"
  >
    <div class="nameAndImg" v-if="(this.sizeWindow < 768)">
			<div class="imgName">
				<div class="image_div position-relative mx-auto">
					<img :src="getImg(user.photo)" class="img_user" @error="errorImage" />
				</div>
			</div>
			<div class="name">
				<p class="titleMobile">
				Olá,
				<br />
				<strong>{{ user.displayName }}!</strong>
				</p>
			</div>
		</div>
    <div class="col-md-12 pr-0 text-center">
      <p class="title">
        Obrigado pelo trabalho<img v-if="(this.sizeWindow < 768)" src="../../assets/image/iconesMobile/emojiLogout.svg" width="auto" height="20px">
        <br />
        <strong v-if="(this.sizeWindow >= 768)">{{ user.displayName }}!</strong>
      </p>
    </div>
    <div class="col-md-12" v-if="(this.sizeWindow >= 768)">
      <p class="texto text-left texto">
        Informe o Valor que está sendo
        <strong>RETIRADO em DINHEIRO </strong> e está sendo entregue ao
        Franqueado.
      </p>
    </div>
    <div class="col-md-12" id="informeMobile" v-if="(this.sizeWindow < 768)">
      <p class="texto text-left texto">
        <strong>Informe o Valor que está sendo</strong>
        <strong class="informeRed"> RETIRADO em DINHEIRO <br/></strong> <strong> e está sendo entregue ao
        Franqueado.</strong>
      </p>
    </div>
    <div class="caixa_cp px-0 my-2 mt-5 mb-5">
      <caixa class="col-md-12" @changeValueNota="changeValueNota" />
    </div>
    <btn
      msg="Retirada Dinheiro"
      class="col-md-10 mx-auto"
      v-show="$parent.cp == 'retirarDinheiro'"
      @trocaComponent="trocaComponent"
    />
  </div>
</template>

<script>
import caixa from "../caixacp/_retiradaCaixa.vue";
import btn from "../caixacp/_Caixa_btn";
export default {
  components: {
    caixa,
    btn
  },
  data() {
    return {
      totalValue: 0,
      nameComponent: 'retirarDinheiro',
      valuesTotal: 200,
      sizeWindow: 0
    };
  },
  mounted() {
    this.sizeWindow = $(window).width();
  },
  methods: {
    trocaComponent(e) {
      this.$emit("trocaComponent", e);
    },
    changeValueNota(value) {
      this.totalValue = value;
    }
  },
  computed: {
    user() {
      return this.$store.state.login.userLogin;
    }
  }
};
</script>

<style scoped>
.texto {
  font-size: 16px;
  text-align: center !important;
  color: blue;
  line-height: 1.4;
}
.input_default {
  color: #2474b2;
}
.title strong {
  margin-bottom: 0.1rem !important;
  color: #2474b2;
}
/* .texto {
  color: #868686 !important;
  font-size: 13px;
} */
.title {
  color: #868686 !important;
}

.image_div {
  overflow: hidden;
  width: 100px;
  overflow: hidden;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #2474b2;
}

.image_div .img_user {
  object-fit: cover;
  height: auto;
  min-height: 100%;
  width: 100%;
  object-position: top;
}
.caixa_cp {
  max-height: 230px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}
/* width */
.caixa_cp::-webkit-scrollbar {
  width: 5px;
  background: #2474b2;
}

/* Track */
.caixa_cp::-webkit-scrollbar-track {
  border-radius: 0px;
}

/* Handle */
.caixa_cp::-webkit-scrollbar-thumb {
  background: #2474b2;
  border-radius: 0px;
}
.nameAndImg{
	display: flex;
	flex-direction: row;
	align-items: center;
  justify-content: center;
	margin-bottom: 4%;
}
.name{
	display: flex;
  text-align: initial;
	color:#F38235;
	margin-left: 10px;
}
.titleMobile{
	font-size: 17px;
}
.informeRed{
  color: red;
}
@media screen and (max-width:767px){
  .title{
    font-size: 17px;
  }
  .caixa_cp{
    overflow-y: hidden;
    border-radius: 16px;
    width: 85%;
  }
}
</style>
