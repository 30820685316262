<template>
  <div>
    <div>
      <div
        class="row align-items-center"
        :class="columColor(item.qtdNota)"
        v-for="(item, index) in notas"
        :key="index"
      >
      
        <div class="col-md-4 text-center py-1" id="notasCont">
          <img :src="getDinheiroImg(item.valorNota)" class="notas" />
        </div>
        <div class="col-md-4 text-center input_camp py-1" id="inputNotasCont">
          <div class="d-flex justify-content-center align-items-center espaco">
           		<money 
								key="money"
								v-model="valueCaixaRetirada"
								v-bind="money"
								 class="inputValue mx-2"
								@change.native="getNewvalue(valueCaixaRetirada)"
							></money>
          </div>
        </div>
      
      </div>
    </div>
  </div>
</template>

<script>
import { Money } from "v-money";
export default {
  components: { Money },
  data() {
    return {
      valueCaixaRetirada: 0,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
		  },
      notas: [
        {
          valorNota: 19,
          qtdNota: 0,
          id: 19,
        },
      ],
    };
  },
  mounted () {
    let dados = sessionStorage.getItem("notasValueRetirada")
    //let newDados = this.getNewvalue(dados)
    if(dados) {
    //  console.log( this.getNewvalue(dados))

      this.valueCaixaRetirada = dados;
    }
  },
  methods: {
    columColor(val) {
      var classe;
      if (val > 0) {
        classe = "value_colum";
      } else {
        classe = "no_value_colum";
      }
      return classe;
    },
    getNewvalue(val) {
     // this.notas[index].qtdNota = val;
      if(val === '') {
        return 0
      }
      this.$emit("changeValueNota", val);

      console.log(this.valueCaixaRetirada)

      this.$parent.sessionStorageRetirada = this.valueConvertBR(this.valueCaixaRetirada)
    },
  },
  watch: {
    notas: function () {},
  },
  computed: {},
};
</script>

<style scoped>
.cartao {
  display: none;
}
.input_camp {
  background: #2474b2;
}
.inputValue {
  font-weight: 500;
  width: 80px;
  height: 50px;
  background-color: #6198c2;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  text-align: center;
  color: #ffffff;
}

.value_colum {
  background-color: #175d93;
  border: 1px solid #ffffff;
}
.no_value_colum {
  background-color: #e3e3e3;
  border: 1px solid #ffffff;
  justify-content: center;
}
.value_colum .input_camp {
  background: #2474b2;
}
.no_value_colum .input_camp {
  background: none;
}
.icon_div {
  background-color: #ffffff;
  height: 20px;
  display: flex;
  width: 20px;
  min-width: 20px;
  border-radius: 50%;
}
.icon_div i {
  color: #2474b2;
  font-size: 10px;
}
.value_colum >>> p {
  color: #ffffff;
}
.no_value_colum >>> p {
  color: #868686;
}
.notas {
  width: 100%;
}
p {
  margin-bottom: 0;
  font-weight: 500;
}
.disableds {
  background-color: rgb(85, 80, 80);
}
@media screen and (max-width:767px){
  .notas{
    width: 85%;
  }
  
  #notasCont{
    width: 39%;
    display: flex;
    justify-content: flex-end;
  }
  #inputNotasCont{
    width: 50%;
  }
  .inputValue{
    width: 100%;
    border: #6198c2 1px solid;
    background-color: #ffff;
    color: #797984;
  }
  .no_value_colum{
    height: 100px;
  }
  
}
</style>
